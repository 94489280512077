export default function (context, inject) {
  const setLocalePreference = async (locale) => {
    if (!context.$auth.loggedIn) {
      return;
    }
    if (!context.i18n.localeCodes.includes(locale)) {
      return;
    }
    if (context.$auth.user.language === locale) {
      return;
    }
    const result = await context.$axios.post(`/api/user/locale/${locale}`);
    context.$auth.setUser(result.data);
  }
  inject('setLocalePreference', setLocalePreference)
  context.$setLocalePreference = setLocalePreference;
  context.app.$setLocalePreference = setLocalePreference;
}
