//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppFooterNav',
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    defaultItemOptions: {
      to: '/',
      text: '',
      title: '',
      className: ''
    }
  })
}
