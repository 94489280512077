//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppLocker',

  data: () => ({
    sitePassword: '',
    error: null
  }),
  computed: {
    locked () {
      return this.$store.state.app.locked
    },
    password () {
      return this.$store.state.app.rtpp
    }
  },
  methods: {
    signin () {
      if (this.sitePassword !== this.password) {
        this.showError()
        return
      }

      this.$store.dispatch('app/SET_STATE', { locked: false })
    },
    showError () {
      this.error = false
    }
  }
}
