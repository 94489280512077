//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapGetters } from 'vuex'
import { gsap } from 'gsap'
import AppMainMenuItem from '@/components/app/AppMainMenuItem'

export default {
  name: 'AppMainMenu',
  components: {
    AppMainMenuItem
  },
  data: () => ({}),
  computed: {
    menuNavItems () {
      if (!this.$auth.loggedIn) {
        return []
      }
      return [
        // {
        //   to: this.localePath('/'),
        //   text: this.$t('menu.header.Procurement')
        // },
        {
          to: this.localePath('/vendor-registration'),
          text: this.$t('menu.header.vendorRegistration')
        }
      ]
    },
  },
  watch: {
    '$route' (to, from) {
      const self = this
      setTimeout(() => {
        self.$store.commit('SET_STATE', {
          prop: 'isMainMenuOpen',
          value: false
        })
      }, 500)
    }
  },
  mounted () {

  },
  methods: {
    async onLogout () {
      await this.$auth.logout()
      // this.$router.replace('/')
      this.$store.commit('SET_STATE', {
        prop: 'isMainMenuOpen',
        value: false
      })
    },
    enterMenu (el, done) {
      this.initAnimation(el, done)
      this.animateIn()
    },
    leaveMenu (el, done) {
      gsap.to(el, {
        duration: 0.3,
        opacity: 0,
        onComplete: () => {
          done()
        }
      })
    },
    initAnimation (el, done) {
      const self = this

      // Get items
      // (this.$refs.___NavItem - array of compoents)
      const primaryNavItems = this.$refs.primaryNavItem.map(c => c.$el)

      gsap.set(primaryNavItems, { opacity: 0, y: 32 })

      self.$timeline = gsap.timeline({
        paused: true,
        delay: 0,
        onComplete: () => {
          done()
        }
      })

      self.$timeline
        .to(primaryNavItems, 1.2, {
          y: 0,
          opacity: 1,
          ease: 'Power4.easeOut',
          clearProps: 'all',
          stagger: 0.05
        })
    },
    animateIn () {
      this.$timeline.play()
    },
    animateOut () {
      this.$timeline.reverse()
    }
  }
}
