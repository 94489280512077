// STATE -----------------------------------------------------------------------

export const state = () => ({
  locked: true,
  rtpp: 'malina'
})

// GETTERS ---------------------------------------------------------------------

export const getters = {
  getState: state => prop => state[prop]
}

// MUTTATIONS ------------------------------------------------------------------

export const mutations = {
  SET_STATE: (state, { prop, value }) => { state[prop] = value }
}

// ACTIONS  --------------------------------------------------------------------

export const actions = {
  SET_STATE: ({ commit }, obj) => {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        commit('SET_STATE', {
          prop: key,
          value: obj[key]
        })
      }
    }
  }
}
