export default ({ app, $axios }) => {
  $axios.interceptors.response.use(response => response, function (error) {
    if (error.response) {
      if (error.response.config && error.response.config.url.endsWith('logout')) {
        return Promise.reject(error);
      }
      if (error.response.status && error.response.status === 401) {
        app.$auth.logout()
      }
    }
    return Promise.reject(error);
  });
}
