//
//
//
//
//
//
//
//

export default {
  name: 'TextButton',
  props: {
    text: {
      type: String,
      required: true
    },
    url: {
      type: String,
      default: '#'
    }
  },
  computed: {
    buttonStyleObject () {
      return {
        // color: this.textColor,
        // background: this.background,
        // borderColor: this.borderColor
      }
    }
  }

}
