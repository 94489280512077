// STATE -----------------------------------------------------------------------

export const state = () => ({
  isMainMenuOpen: false,
  isHeroLight: true,
  locale: 'en'
})

// GETTERS ---------------------------------------------------------------------

export const getters = {
  /**
   * Generic getter
   *
   * @example
   * ```
   * this.$store.getters['getState']('isMenuOpen')
   * ```
   *
   * @param  {String} prop [description]
   * @return {Mixed}
   */
  getState: state => prop => (prop in state)
    ? state[prop]
    : undefined,
  locale: state => state.locale
}

// MUTTATIONS ------------------------------------------------------------------

export const mutations = {
  /**
   * Generic mutation
   *
   * @example
   * ```
   * this.$store.commit('SET_STATE', {
   *  prop: 'isMenuOpen',
   *  value: true
   * })
   * ```
   *
   * @param  {String} prop [description]
   * @return {Mixed}
   */
  SET_STATE: (state, { prop, value }) => { state[prop] = value }

}

// ACTIONS  --------------------------------------------------------------------

export const actions = {
  SET_STATE ({ commit }, obj) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        commit('SET_STATE', { prop: key, value: obj[key] })
      }
    }
  }
}
