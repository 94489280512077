//
//
//
//
//
//
//
//
//
//
//
//

import { gsap } from 'gsap'

export default {
  name: 'CustomTransition',
  props: {
    delay: {
      type: Number,
      default: 0.1
    },
    noAdditionalClasses: {
      type: Boolean,
      default: false
    },
    pageTransition: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    duration: 0.6
  }),
  computed: {
    dl () {
      return this.delay || 0.1
    }
  },
  methods: {
    beforeEnter (el, done) {},
    afterEnter (el, done) {},
    enter (el, done) {
      const self = this

      self.tl = gsap.timeline({
        onComplete: () => {
          if (self.pageTransition) {
            self.$store.dispatch('app/SET_STATE', {
              transitioning: false
            })
          }
          done()
        }
      })

      // delay
      const dl = this.pageTransition ? this.dl : 0

      // animate from
      const from = {
        opacity: 0
      }

      // animate to
      const to = {
        opacity: 1,
        delay: dl
      }

      this.tl.fromTo(el, this.dur, from, to)
    },
    leave (el, done) {
      if (this.pageTransition) {
        this.$store.dispatch('app/SET_STATE', {
          transitioning: true
        })
      }

      if (!this.noAdditionalClasses) {
        const classes = ['transitioning-out']
        if (this.pageTransition) {
          classes.push('h-100')
        }

        el.classList.add(...classes)
      }

      // delay
      const dl = this.pageTransition ? this.dl : 0

      gsap.to(el, {
        duration: this.dur,
        opacity: 0,
        delay: dl,
        onComplete: () => {
          done()
        }
      })
    }
  }
}
