//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageWrapper',
  mounted () {}
}
