//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FormSection',
  props: {
    title: {
      type: String,
      default: ''
    },
    status: {
      type: [String, null],
      default: null
    },
    open: {
      type: Boolean,
      default: false
    },
    canUnlock: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    statusOpen () {
      return this.status === 'open'
    }
  },
  watch: {
    open: {
      immediate: true,
      handler (val) {
        this.isOpen = val
      }
    }
  },
  methods: {
    toggleOpen () {
      this.isOpen = !this.isOpen
      this.$emit('openChange', this.isOpen)
    }
  }
}
