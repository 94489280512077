import axios from 'axios'
import forEach from 'lodash/forEach'

import { formatISO } from 'date-fns'
// import { mapState } from 'vuex'

const formMixin = {
  data () {
    return {
      // @TODO create a way to debug a form
      debug: false,
      // @TODO create a way to mock an api request
      mock: false,
      mockSuccess: false,
      mockResponse: {
        success: true
      },
      mockErrorResponse: {
        success: false
      },

      // Form data for sending
      formData: {},
      filledFormData: {},
      hiddenFormData: {},

      // Form fields definitions
      // formFields: [],

      // API Endpoint to send a form data to.
      // @TODO create a way to force user of the mixin to setup it's own endpoint.
      // formEndpoint: 'http://localhost:8000/form/contact',
      formEndpoint: false,
      ignoreEndpoint: false,

      // flags
      isValid: false,
      isSubmitted: false,
      isSending: false,
      submittingError: false,
      // intended for gdpr compliance
      isConfirmed: false,
      redirectAfterSuccess: false
    }
  },

  computed: {
    /**
     * @return {String} Endpoint url.
     */
    $_endpoint () {
      return ''
    }
  },

  created () {
    this.$_resetFormData()
  },

  mounted () {
    this.$_resetFormData()
  },

  methods: {
    $_resetFormData () {
      const self = this
      this.formData = {}
      forEach(this.formFields, (field) => {
        let value = null
        value = this.fieldRules()[field.options.type].default
        if (Object.prototype.hasOwnProperty.call(this.filledFormData, field.name)) {
          if (this.filledFormData[field.name] !== null) {
            value = this.filledFormData[field.name]
          }
        }
        self.$set(self.formData, field.name, value)
      })
      for (const key in this.hiddenFormData) {
        if (Object.hasOwnProperty.call(this.hiddenFormData, key)) {
          const element = this.hiddenFormData[key]
          self.$set(self.formData, key, element)
        }
      }
    },

    updateFormData (fieldName, value) {
      if (this.debug) {
        console.log('Updating form data', fieldName, value)
      }

      if (value instanceof Event) {
        value = value.target.value
      }

      if (fieldName in this.formData) {
        this.formData[fieldName] = value
      } else {
        this.$set(this.formData, fieldName, value)
      }

      if (this.debug) {
        console.log('updated form data debug', this.formData, value)
      }
    },

    async validateForm () {
      const self = this

      return await new Promise((resolve, reject) => {
        // const isValid = await this.$refs.observer.validate()

        self.$refs.observer
          .validate()
          .then((isValid) => {
            self.isValid = isValid
            resolve(isValid)
          })
          .catch((err) => {
            console.error('[FormMixin] there was an error', err)
            reject(err)
          })
      })
    },

    submitForm (event) {
      const self = this
      if (self.isSubmitted) { return }
      self.isSubmitted = true

      this.validateForm()
        .then((isValid) => {
          if (!isValid) { return false }
          self.$_sendFormData()
        })
        .finally(() => {
          this.isSubmitted = false
        })
    },

    $_sendFormData () {
      const self = this
      if (self.isSending) { return }
      self.isSending = true

      // Endpoint
      const reqUrl = this.$_endpoint

      // Data
      const reqData = this.$_getFormData()

      if (this.debug) {
        console.log('debug: Sending form data', reqUrl, reqData)
      }

      if (this.mock) {
        setTimeout(() => {
          if (self.mockSuccess) {
            self.$_onSuccess(self.mockResponse)
          } else {
            self.$_onError(self.mockErrorResponse)
          }
          self.isSending = false
        }, 1000)
        return
      }

      this.$_sendRequest(reqUrl, reqData)
        .then(this.$_onSuccess)
        .catch(this.$_onError)
    },

    $_sendRequest (url, data) {
      // Options
      // @TODO create a configurable request options.
      const reqOptions = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      // Send
      return axios.post(url, data, reqOptions)
    },

    $_onSuccess (response) {
      if (this.debug) {
        console.log('[FormMixin] Form sent.', response)
      }

      // @TODO watch for preset form fields
      // reset (empty) form fields
      // this.$_resetFormData()

      // Reset validation observer
      // this.$validator.reset()
      if (!this.redirectAfterSuccess) {
        this.$refs.observer.reset()
        this.isSending = false
      }
      this.formSubmitted = true
      // this.$bus.$emit('form-on-success')
      this.$emit('form-on-success')
    },

    $_onError (error) {
      console.log('[FormMixin] Form error.', error)
      this.submittingError = true
      this.isSending = false
      // @TODO emit errors object
      // this.$bus.$emit('form-on-error')
      this.$emit('form-on-error')
    },

    $_getFormData () {
      const self = this
      const data = {}
      forEach(this.formData, (value, key) => {
        const field = this.formFields.find(f => f.name === key)
        if (!field) {
          data[key] = value
          return
        }
        const rules = self.fieldRules()[field.options.type]
        if (!rules) {
          data[key] = value
          return
        }
        data[key] = self.fieldRules()[field.options.type].parse(value)
      })

      return data
    },
    showError (message, title, type) {
      const options = {
        toaster: 'b-toaster-top-center',
        variant: type || 'danger',
        solid: true
      }
      if (title) {
        options.title = title
      }
      this.$bvToast.toast(message, options)
    },

    showServerError (title) {
      this.showError(this.$t('form.serverErrorMessage'), title, 'danger')
    },

    showMessage (message, title, type) {
      const options = {
        toaster: 'b-toaster-top-center',
        variant: type || 'default',
        solid: true
      }
      if (title) {
        options.title = title
      }
      this.$bvToast.toast(message, options)
    },
    fieldRules () {
      return {
        file: {
          default: null,
          parse: (val) => {
            if (val === null) {
              return val
            }
            if (Array.isArray(val)) {
              return val.map((item) => {
                return {
                  id: item.id,
                  type: item.type
                }
              })
            }

            return {
              id: val.id,
              type: val.type
            }
          }
        },
        relation: {
          default: null,
          parse: (val) => {
            if (val === null) {
              return val
            }
            if (Array.isArray(val)) {
              return val.map((item) => {
                return {
                  id: item.id,
                  type: item.type
                }
              })
            }

            return {
              id: val.id,
              type: val.type
            }
          }
        },
        textarea: {
          default: '',
          parse: (val) => {
            return val
          }
        },
        text: {
          default: '',
          parse: (val) => {
            return val
          }
        },
        select: {
          default: '',
          parse: (val) => {
            return val
          }
        },
        email: {
          default: '',
          parse: (val) => {
            return val
          }
        },
        password: {
          default: '',
          parse: (val) => {
            return val
          }
        },
        number: {
          default: null,
          parse: (val) => {
            return val
          }
        },
        integer: {
          default: 0,
          parse: (val) => {
            return val
          }
        },
        percentage: {
          default: null,
          parse: (val) => {
            return val
          }
        },
        date: {
          default: null,
          parse: (val) => {
            if (val === '' || val === null) {
              return null
            }
            // eslint-disable-next-line unicorn/prefer-includes
            // if (!val.includes('T')) {
            //   val = val + 'T00:00:00+00:00'
            // }
            // return val
            return formatISO(new Date(val), { representation: 'date' })
          }
        },
        datetime: {
          default: null,
          parse: (val) => {
            if (val === '' || val === null) {
              return null
            }
            // eslint-disable-next-line unicorn/prefer-includes
            // if (!val.includes('T')) {
            //   val = val + 'T00:00:00+00:00'
            // }
            // return val
            return formatISO(new Date(val))
          }
        },
        term: {
          default: false,
          parse: (val) => {
            // return val?.fields?.agreed
            return val
          }
        },
        boolean: {
          default: null,
          parse: (val) => {
            // return val?.fields?.agreed
            return val
          }
        }
      }
    }
  }
}

export default formMixin
