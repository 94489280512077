//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TextareaInput',

  props: {
    value: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },

  data: () => ({}),

  watch: {
    value (val) {
      this.$nextTick(() => {
        this.$refs.in.value = val
      })
    }
  },

  methods: {
    updateValue ($event) {
      this.$emit('input', $event)
    }
  }
}
