export default function (context) {
  const { store, app, route, redirect } = context
  const activePage = store.getters.activePage
  const { $auth, $cookiz } = app
  const locale = store.getters.locale
  if (shouldRedirect($auth, activePage)) {
    if (!$auth.loggedIn) {
      let redirecUrl =
        store.getters['entities/getEntityUrlsByPageTemplate']('Login')
      redirecUrl = redirecUrl[locale]

      // if (process.server) {
      // we are redirecting to the login page from the server!
      // to make sure we afterwards can retrieve the page we came from, we set a cookie.
      $cookiz.set('auth-redirect', route.path, {
        path: '/'
      })
      // }
      // $auth.redirect('login')
      redirect(redirecUrl)
      return
    }
    if (isVerified(activePage) && $auth.loggedIn) {
      let redirecUrl =
        store.getters['entities/getEntityUrlsByPageTemplate']('VerifyEmailNotification')
      redirecUrl = redirecUrl[locale]
      redirect(redirecUrl)
      return
    }
    redirect('/')
  }
}

const isProtected = function (page) {
  if (
    page &&
    Object.prototype.hasOwnProperty.call(page.fields, 'page_protection') &&
    page.fields.page_protection === 'protected'
  ) {
    return true
  }
  if (
    page &&
    page.entity_type === 'eoi'
  ) {
    return true
  }
  return false
}
const isVerified = function (page) {
  if (
    page &&
    Object.prototype.hasOwnProperty.call(page.fields, 'page_protection') &&
    page.fields.page_protection === 'verified'
  ) {
    return true
  }
  return false
}
const isGuest = function (page) {
  if (
    page &&
    Object.prototype.hasOwnProperty.call(page.fields, 'page_protection') &&
    page.fields.page_protection === 'guest'
  ) {
    return true
  }
  return false
}
const shouldRedirect = function ($auth, page) {
  if (
    isVerified(page) &&
    (!$auth.loggedIn || !$auth.user.email_verified_at)
  ) {
    return true
  }
  if (isProtected(page) && !$auth.loggedIn) {
    return true
  }
  if (isGuest(page) && $auth.loggedIn) {
    return true
  }
  return false
}
