//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  name: 'Hamburger',
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState([
      'isAppMenuMobileOpen'
    ])
  }
}
