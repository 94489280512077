/* eslint-disable no-undef */
/* eslint-disable camelcase */
import Vue from 'vue'
import { DashboardModal } from '@uppy/vue'

// PLUGIN
const UppyInstall = {
  install (Vue) {
    // Regsiter components globally
    Vue.component('DashboardModal', DashboardModal)
  }
}

Vue.use(UppyInstall)
