//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import forEach from 'lodash/forEach'
import RelationForm from '~/components/company/RelationForm'
import formConfig from '~/config/forms.json'

export default {
  name: 'RelationInput',
  components: {
    RelationForm
  },

  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    subType: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    busy: false,
    modalOpened: false,
    formMode: 'create',
    activeEntity: null,
    modalTitle: '',
    modalForm: '',
    modalFormModel: null
  }),

  computed: {
    typeIcon () {
      return formConfig[this.subType].icon
    }
  },

  methods: {
    updateValue ($event, action) {
      if (!this.multiple) {
        if (action === 'remove') {
          this.$emit('input', null)
          return
        }

        this.$emit('input', $event)
        return
      }

      let temp = []
      if (this.value && Array.isArray(this.value)) {
        forEach(this.value, (item) => {
          temp.push(item)
        })
      }
      if (action === 'add') {
        temp.push($event)
        this.$emit('input', temp)
        return
      }

      const index = temp.findIndex(item => item.id === $event.id)
      temp = temp.filter(item => item.id !== $event.id)

      if (action === 'replace') {
        if (index > -1) {
          temp.splice(index, 0, $event)
        } else {
          temp.push($event)
        }
      }

      this.$emit('input', temp)
    },
    openAddModal () {
      this.modalTitle = `${this.$t('form.addLabel')} ${this.$t('types.' + this.subType + '.label')}`
      this.modalFormModel = null
      this.formMode = 'create'
      this.$refs.formModal.show()
    },
    openEditModal (entity) {
      this.modalTitle = `${this.$t('form.editLabel')} ${this.$t('types.' + this.subType + '.label')}`
      this.modalFormModel = entity
      this.formMode = 'edit'
      this.$refs.formModal.show()
    },
    openRemoveModal (entity) {
      this.activeEntity = entity
      this.$refs.removeModal.show()
    },
    onModalSave (payload) {
      const entity = payload.data.data
      this.$refs.formModal.hide()
      if (this.multiple && this.formMode === 'edit') {
        this.updateValue(entity, 'replace')
        return
      }
      this.updateValue(entity, 'add')
    },
    onFormCancel () {
      this.$refs.formModal.hide()
    },
    async onRemove () {
      this.busy = true
      let result
      try {
        await this.$axios.get('sanctum/csrf-cookie')
        result = await this.$axios.delete(`/api/data/subforms/${this.subType}/${this.activeEntity.id}`)
        this.updateValue(this.activeEntity, 'remove')
        this.$refs.removeModal.hide()
      } catch (e) { }
      this.busy = false
      return result
    }
  }
}
