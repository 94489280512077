//
//
//
//
//
//


export default {
  name: 'LogoLoader'
}
