//
//
//
//
//
//

export default {
  props: {
    status: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      statusColors: {
        open: 'gray',
        denied: 'danger',
        predenied: 'orange',
        pending_approval: 'warning',
        preapproved: 'info',
        approved: 'success'
      }
    }
  },

  computed: {
    statusColor () {
      return this.statusColors[this.nurfedStatus]
    },
    nurfedStatus () {
      return {
        open: 'open',
        denied: 'denied',
        predenied: 'pending_approval',
        pending_approval: 'pending_approval',
        preapproved: 'pending_approval',
        approved: 'approved'
      }[this.status]
    }
  }
}
