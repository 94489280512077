//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageSection',
  props: {
    threshold: {
      type: Array,
      required: false,
      default: () => [0]
    },
    isLight: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    isIntersecting: false
  }),
  computed: {},
  watch: {
    isIntersecting (val) {
      this.$emit('intersect', val)
    }
  },
  mounted () {
    // this.initAppHeaderObserver()
  },
  methods: {
    onEnter (entry) {
      this.isIntersecting = true
      this.$emit('enter', entry)
    },
    onLeave (entry) {
      this.isIntersecting = false
      this.$emit('leave', entry)
    },
    onChange (entry) {
      this.$emit('change', entry)
    }
  }
}
