//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AnimatedLink',
  props: {
    to: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
