//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppMainMenuItem',
  props: {
    path: {
      type: String,
      default: null
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    hasPath () {
      return this.path !== null
    }
  },
  methods: {
    onClick () {
      this.$emit('linkClick')
    }
  }
}
