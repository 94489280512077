//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TermInput',

  props: {
    value: {
      type: [Boolean, Number],
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      checkValue: false
    }
  },

  computed: {
    // term () {
    //   return this.value ? this.value.fields.agreement_term : null
    // },
    // label () {
    //   return this.term?.fields?.term_instructions[this.locale]
    // },
    // locale () {
    //   return this.$store.state.app.locale
    // }
  },

  watch: {
    value: {

      // This will let Vue know to look inside the array
      deep: true,
      immediate: true,

      // We have to move our method to a handler field
      handler (newVal) {
        this.checkValue = newVal
      }
    }
  },

  methods: {
    updateValue (val) {
      // const oldValue = this.value?.fields?.agreed
      // if (typeof oldValue !== 'undefined') {
      //   const newVlaue = Object.assign({}, this.value)
      //   newVlaue.fields.agreed = this.checkValue
      //   if (this.validator) {
      //     this.validator.syncValue(this.checkValue)
      //   }
      //   this.$emit('input', newVlaue)
      // }
      this.$emit('input', val)
    }
  }
}
