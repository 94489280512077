//
//
//
//
//
//
//
//
//

export default {
  name: 'ChangeLanguageLink',
  props: {
    locale: {
      type: String,
      required: true
    }
  },

  computed: {
    linkComponent () {
      if (!this.translationUrl) {
        return 'span'
      }

      return 'nuxt-link'
    },
    translationUrl () {
      // return this.$store.state.app[this.locale + '_translation']
      // const url = this.$store.getters['app/TranslationUrl'](this.locale)
      return url
    }
  }
}
