//
//
//

import DashboardPlugin from '@uppy/dashboard'
import { shallowEqualObjects } from 'shallow-equal'
function _extends () {
  const _e = Object.assign || function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i]
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key]
        }
      }
    }
    return target
  }
  return _e.apply(this, arguments)
}

export default {
  name: 'UppyModal',
  props: {
    uppy: {
      type: Object,
      default: null
    },
    props: {
      type: Object,
      default: null
    },
    plugins: {
      type: Array,
      default: () => []
    },
    open: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      plugin: {},
      hasUppy: false
    }
  },
  watch: {
    uppy: {
      immediate: false,
      handler (current, old) {
        if (old !== current) {
          this.uninstallPlugin(old)
          this.installPlugin()
        }
      }
    },
    open: function open (current, old) {
      if (current && !old) {
        this.plugin.openModal()
      }

      if (!current && old) {
        this.plugin.closeModal()
      }
    },
    props: function props (current, old) {
      if (!shallowEqualObjects(current, old)) {
        this.plugin.setOptions(_extends({}, current))
      }
    }
  },
  mounted () {
    this.installPlugin()
  },
  beforeDestroy: function beforeDestroy () {
    this.uninstallPlugin(this.uppy)
  },
  beforeUnmount: function beforeUnmount () {
    this.uninstallPlugin(this.uppy)
  },
  methods: {
    installPlugin: function installPlugin () {
      if (this.uppy === null) {
        return
      }

      const uppy = this.uppy

      const options = _extends({
        id: 'vue:DashboardModal',
        plugins: this.plugins,
        proudlyDisplayPoweredByUppy: false
      }, this.props, {
        target: 'body'
      })

      uppy.use(DashboardPlugin, options)
      this.plugin = uppy.getPlugin(options.id)

      if (this.open) {
        this.plugin.openModal()
      }
    },
    uninstallPlugin: function uninstallPlugin (uppy) {
      if (uppy === null) {
        return
      }
      uppy.removePlugin(this.plugin)
    }
  }
}
