export default function(context) {
  context.$auth.onRedirect((to, from) => {
    const referrer = context.route.query.referrer
    if (from.toLowerCase() === '/sign-in' && referrer) {
      delete context.route.query.referrer
      if (/(^[.]{1,2}\/)|(^\/(?!\/))/.test(referrer)) {
        return referrer
      } else {
        window.location.replace(referrer)
      }
    }
    // you can optionally change `to` by returning a new value
  })
 }
