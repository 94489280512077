/* eslint-disable no-undef */
/* eslint-disable camelcase */
import Vue from 'vue'
import {
  localize,
  extend,
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'

// @TODO select locale dynamically
import enLocaleMessages from 'vee-validate/dist/locale/en.json'
import srLocaleMessages from 'vee-validate/dist/locale/sr_Latin.json'

/**
 * Just comment / uncomment rules that you want in this project
 */

import * as rules from 'vee-validate/dist/rules'

// PLUGIN
const CustomValidation = {
  install (Vue) {
    Object.keys(rules).forEach((rule) => {
      // eslint-disable-next-line import/namespace
      extend(rule, rules[rule])
    })

    extend('strongPassword', {
      getMessage: field => `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
      validate: value => {
        var strongRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*.,<>;:()\-_\+={}\[\]|\\"'?~`±§/])(?=.{8,})/);
        return strongRegex.test(value);
      }
    })

    srLocaleMessages.messages.is = 'Polje {_field_} je obavezno'

    localize({
      en: enLocaleMessages,
      sr: srLocaleMessages
    })

    // Regsiter components globally
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)
  }
}

Vue.use(CustomValidation)
