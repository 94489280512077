//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RadioInput',

  props: {
    value: {
      type: [Number, String],
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    subType: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    radioModel: null
  }),

  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (this.radioModel !== val) {
          this.radioModel = val
        }
      }
    }
  },

  methods: {
    updateValue ($event) {
      this.$emit('input', $event)
    }
  }
}
