//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TextInput',

  props: {
    value: {
      type: [Number, String],
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    subType: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: false
    },
    min: {
      type: [Number, null],
      default: null
    },
    max: {
      type: [Number, null],
      default: null
    },
    step: {
      type: [Number, null],
      default: null
    }
  },

  data: () => ({}),

  watch: {
    // value (val) {
    //   this.$nextTick(() => {
    //     this.$refs.in.value = val
    //   })
    // }
  },

  methods: {
    updateValue ($event) {
      this.$emit('input', $event)
    }
  }
}
