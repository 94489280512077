//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'
export default {
  name: 'SelectInput',
  components: {
    Multiselect
  },

  props: {
    value: {
      type: [Number, String, undefined],
      required: true
    },
    type: {
      type: String,
      default: 'select'
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({}),

  computed: {
    values () {
      return this.options.map(o => o.value)
    }
  },

  watch: {
    // value (val) {
    //   this.$nextTick(() => {
    //     this.$refs.in.value = val
    //   })
    // }
  },

  methods: {
    updateValue ($event) {
      this.$emit('input', $event)
    },

    customLabel (value) {
      const option = this.options.find(o => o.value === value)
      if (option) {
        return option.text
      }
      return ''
    }
  }
}
